<template>
    <svg viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.2078 11.391L11.8116 17.3519C11.1742 18.2161 9.82579 18.2161 9.18844 17.3519L4.79219 11.391C4.04943 10.3838 4.80853 9.00003 6.10374 9.00003H8.89913L8.89913 3.60098C8.89913 2.71679 9.61591 2.00001 10.5001 2.00001C11.3843 2.00001 12.1011 2.71679 12.1011 3.60098L12.1011 9.00003H14.8963C16.1915 9.00003 16.9506 10.3838 16.2078 11.391Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'DownloadIcon',
    };
</script>
