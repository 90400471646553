import { render, staticRenderFns } from "./RecoveryModal.vue?vue&type=template&id=563bb3a9&scoped=true"
import script from "./RecoveryModal.vue?vue&type=script&lang=js"
export * from "./RecoveryModal.vue?vue&type=script&lang=js"
import style0 from "./RecoveryModal.vue?vue&type=style&index=0&id=563bb3a9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563bb3a9",
  null
  
)

export default component.exports