<template>
    <modal class="recovery-modal" :title="$t('recovery.title')" v-on="$listeners" :close-button="closeButton">
        <slot name="before" />

        <recovery-codes :codes="codes" />

        <slot name="after" />
    </modal>
</template>

<script>
    import Modal from '@/components/Modal';
    import RecoveryCodes from '@/components/tfa/RecoveryCodes';

    export default {
        name: 'RecoveryModal',
        components: {
            Modal,
            RecoveryCodes,
        },
        props: {
            codes: {
                type: Array,
                required: true,
            },
            closeButton: {
                type: Boolean,
                default: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .recovery-modal::v-deep .modal__body > :not(:last-child) {
        margin-bottom: 1rem;
    }

    @media screen and (min-width: 22.5em) {
        .recovery-modal::v-deep .modal__body > *:not(:last-child) {
            margin-bottom: 1.5rem;
        }
    }

    @media screen and (min-width: 120em) {
        .recovery-modal::v-deep .modal__body > *:not(:last-child) {
            margin-bottom: 2rem;
        }
    }
</style>
