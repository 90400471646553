<template>
    <div class="recovery-codes">
        <ul v-if="codes.length" ref="codes">
            <li v-for="(code, index) in codes" :key="index">{{ code }}</li>
        </ul>

        <div class="recovery-codes__buttons">
            <a :href="downloadUrl" download="recovery_codes.txt" :style="{ pointerEvents: codes.length ? 'auto' : 'none' }">
                <text-button full>
                    {{ $t('recovery.download') }}
                    <template #icon>
                        <download-icon />
                    </template>
                </text-button>
            </a>

            <text-button :disabled="!codes.length" theme="info" full @click="copyToClipboard($refs.codes, $event)">
                {{ $t('recovery.copy') }}
                <template #icon>
                    <component :is="iconComponent" />
                </template>
            </text-button>
        </div>
    </div>
</template>

<script>
    import copyMixin from '@/mixins/copyMixin';
    import DownloadIcon from '@/components/icons/DownloadIcon';
    import TextButton from '@/components/buttons/TextButton';

    export default {
        name: 'RecoveryCodes',
        components: {
            DownloadIcon,
            TextButton,
        },
        props: {
            codes: {
                type: Array,
                required: true,
            },
        },
        mixins: [copyMixin],
        computed: {
            downloadUrl() {
                return URL.createObjectURL(new Blob([this.codes.join('\n')], { type: 'text/plain', endings: 'native' }));
            },
        },
    };
</script>

<style lang="scss" scoped>
    .recovery-codes ul,
    .recovery-codes__buttons {
        display: grid;
        grid-gap: 0.5rem;
    }

    .recovery-codes ul {
        margin-bottom: 1rem;
    }

    .recovery-codes li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 4rem;
        border-radius: 0.8rem;
        background-color: #1a2132;
        font-family: 'Gotham Book', sans-serif;
        font-weight: 325;
    }

    @media screen and (min-width: 22.5em) {
        .recovery-codes ul {
            margin-bottom: 1.5rem;
        }
    }

    @media screen and (min-width: 30em) {
        .recovery-codes ul,
        .recovery-codes__buttons {
            grid-template-columns: 1fr 1fr;
        }
    }

    @media screen and (min-width: 120em) {
        .recovery-codes ul,
        .recovery-codes__buttons {
            grid-gap: 1rem;
        }

        .recovery-codes ul {
            margin-bottom: 2rem;
        }

        .recovery-codes li {
            font-size: 1.4rem;
        }
    }
</style>
